import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ToastService } from 'src/app/shared/services/toast/toast.service';
import { getControlErrorMessage } from 'src/utils';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  loading: boolean = false;
  showCodePassForm: boolean = false;
  showPassword: boolean = false;
  errorMessage = getControlErrorMessage;

  // Creamos el formulario
  forgotPassForm: FormGroup = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
  });

  forgotPassSubmitForm: FormGroup = this.fb.group({
    code: ['', [Validators.required, Validators.pattern('^[0-9]{6}$')]],
    password: ['', [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private toast: ToastService
  ) {}

  // Getter email
  get email(): AbstractControl | null {
    return this.forgotPassForm.get('email');
  }

  // Getter code
  get code(): AbstractControl | null {
    return this.forgotPassSubmitForm.get('code');
  }

  // Getter password
  get password(): AbstractControl | null {
    return this.forgotPassSubmitForm.get('password');
  }

  /**
   * Método para enviar el formulario forgotPassForm
   * (envío de código al correo)
   */
  onSubmit(): void {
    if (this.forgotPassForm.valid) {
      this.loading = true;
      const { email } = this.forgotPassForm.value;

      this.auth
        .forgotPassword(email)
        .then((user) => {
          this.loading = false;
          this.showCodePassForm = true;
          this.toast.info(`Se envió tu código de verificación al correo ${email}`);
        })
        .catch((err) => {
          this.loading = false;
          this.toast.error(err.message);
        });
    }
  }

  /**
   * Método para enviar el formulario forgotPassSubmitForm
   * (envío de código de verificacíon y nueva contraseña)
   */
  onSubmitPass(): void {
    if (this.forgotPassSubmitForm.valid) {
      this.loading = true;

      const { email } = this.forgotPassForm.value;
      const { code, password } = this.forgotPassSubmitForm.value;

      this.auth
        .forgotPasswordSubmit(
          email,
          code,
          password
        )
        .then((user) => {
          this.loading = false;
          this.router.navigate(['login']);
          this.toast.success('Tu contraseña ha sido actualizada correctamente');
        })
        .catch((err) => {
          this.loading = false;
          this.toast.error(err.message);
        });
    }
  }

  /**
   * Método para volver atrás
   */
  back(): void {
    this.router.navigate(['login']);
  }
}
