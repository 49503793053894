<div class="h-screen bg-white relative flex flex-col justify-center items-center">
  <div class="card">
    <button type="button" (click)="back()" class="btn btn-primary-outline btn-rounded">
      <svg class="w-5 h-5" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 9.32422C0 9.59277 0.0966797 9.8291 0.300781 10.0332L8.81934 18.3584C9.00195 18.5518 9.23828 18.6484 9.51758 18.6484C10.0762 18.6484 10.5059 18.2295 10.5059 17.6709C10.5059 17.3916 10.3877 17.1553 10.2158 16.9727L2.39551 9.32422L10.2158 1.67578C10.3877 1.49316 10.5059 1.24609 10.5059 0.977539C10.5059 0.418945 10.0762 0 9.51758 0C9.23828 0 9.00195 0.0966797 8.81934 0.279297L0.300781 8.61523C0.0966797 8.80859 0 9.05566 0 9.32422Z" fill="currentColor"/>
      </svg>
    </button>

    <!-- Form para resetear contraseña (ingreso de correo) -->
    <div *ngIf="!showCodePassForm; else showingCodePassForm">
      <div class="text-center">
        <img class="max-w-xs md:max-w-sm m-auto" src="../assets/2KeepCash.png" alt="2KeepCash Logo">
        <h1 class="text-3xl text-black font-bold mt-3 mb-1">Recuperar contraseña</h1>
        <p class="font-normal text-base text-gray-light">Ingresa tu correo para recuperar tu contraseña</p>
      </div>

      <form class="my-8" [formGroup]="forgotPassForm" (ngSubmit)="onSubmit()" novalidate>

        <div class="mb-6" >
          <div class="relative">
            <input type="text" id="email" class="form-control form-control-outline peer" formControlName="email" placeholder=" " autofocus />
            <label for="email" class="peer-focus:px-2 peer-focus:text-blue-100 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4">
              Correo
            </label>
          </div>
          <p *ngIf="email?.errors && (email?.dirty || email?.touched)" id="error_email" class="mt-2 text-xs font-medium text-red-600 dark:text-red-400">
            {{errorMessage(email?.errors)}}
          </p>
        </div>
        <button *ngIf="!loading; else resetingPass" type="submit" class="btn btn-primary btn-block mt-9" [disabled]="!forgotPassForm.valid">
          Recuperar contraseña
        </button>
        <ng-template #resetingPass>
          <div class="flex items-center mt-9">
            <svg aria-hidden="true" role="status" class="inline w-6 h-6 m-auto text-blue-100 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
            </svg>
          </div>
        </ng-template>

      </form>
    </div>

    <!-- Form para resetear contraseña (ingreso de código de verificación y nueva contraseña) -->
    <ng-template #showingCodePassForm>
      <div class="text-center">
        <img class="max-w-xs md:max-w-sm m-auto" src="../assets/2KeepCash.png" alt="2KeepCash Logo">
        <h1 class="text-3xl text-black font-bold mt-3 mb-1">Recuperar contraseña</h1>
        <p class="font-normal text-base text-gray-light">Ingresa tu código de verificación y tu nueva contraseña</p>
      </div>

      <form class="my-8" [formGroup]="forgotPassSubmitForm" (ngSubmit)="onSubmitPass()" novalidate>

        <div class="mb-6" >
          <div class="relative">
            <input type="text" id="code" class="form-control form-control-outline peer" formControlName="code" placeholder=" " autofocus />
            <label for="code" class="peer-focus:px-2 peer-focus:text-blue-100 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4">
              Código
            </label>
          </div>
          <p *ngIf="code?.errors && (code?.dirty || code?.touched)" id="error_code" class="mt-2 text-xs font-medium text-red-600 dark:text-red-400">
            {{errorMessage(code?.errors)}}
          </p>
        </div>
        <div class="mb-6">
          <div class="relative">
            <input [attr.type]="showPassword ? 'text' : 'password'" id="password" class="form-control form-control-outline peer" formControlName="password" placeholder=" " />
            <label for="password" class="peer-focus:px-2 peer-focus:text-blue-100 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4">
              Nueva contraseña
            </label>
            <button (click)="showPassword = !showPassword" type="button" class="absolute inset-y-0 right-0 flex items-center px-3">
              <svg class="w-5 h-5 text-black" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.5 18C10.0153 17.9265 8.56154 17.5559 7.22781 16.911C5.89409 16.2662 4.7085 15.3606 3.74372 14.2498C2.66869 13.1208 1.7465 11.8603 1 10.4994C2.18383 8.32404 3.831 6.42473 5.82938 4.93079L9.47556 8.50969C9.19735 8.7608 8.97247 9.0635 8.81389 9.40035C8.65531 9.73719 8.56616 10.1015 8.55158 10.4724C8.53699 10.8432 8.59726 11.2132 8.72892 11.5611C8.86057 11.9089 9.06101 12.2277 9.31867 12.4992C9.57634 12.7706 9.88615 12.9893 10.2302 13.1427C10.5743 13.296 10.9459 13.3809 11.3236 13.3926C11.7013 13.4043 12.0776 13.3424 12.4308 13.2106C12.784 13.0788 13.1072 12.8797 13.3818 12.6247C13.4305 12.5803 13.4774 12.5343 13.5222 12.4867L17.1684 16.0666C15.5397 17.2928 13.552 17.9708 11.5 18V18ZM19.9392 13.4892L9.49556 3.22542C10.1476 3.07568 10.815 3.00005 11.4846 3H11.5C12.9846 3.07353 14.4382 3.44397 15.7719 4.08861C17.1056 4.73325 18.2913 5.63852 19.2563 6.74898C20.3313 7.878 21.2535 9.13855 22 10.4994C21.4205 11.5635 20.7299 12.5655 19.9392 13.4892V13.4892Z" stroke="#010723" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2 1L20 19" stroke="#010723" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
          <p *ngIf="password?.errors && (password?.dirty || password?.touched)" id="error_password" class="mt-2 text-xs font-medium text-red-600 dark:text-red-400">
            {{errorMessage(password?.errors)}}
          </p>
        </div>
        <button *ngIf="!loading; else changingPass" type="submit" class="btn btn-primary btn-block mt-9" [disabled]="!forgotPassSubmitForm.valid">Recuperar contraseña</button>
        <ng-template #changingPass>
          <div class="flex items-center mt-9">
            <svg aria-hidden="true" role="status" class="inline w-6 h-6 m-auto text-blue-100 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
            </svg>
          </div>
        </ng-template>
      </form>
    </ng-template>

  </div>
</div>
