import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService } from './services/auth/auth.service';

import { ToastService } from './services/toast/toast.service';
import { ToastMessageListComponent } from './components/toast-message-list/toast-message-list.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';

@NgModule({
  declarations: [ToastMessageListComponent, ToastMessageComponent],
  imports: [CommonModule],
  providers: [AuthService, ToastService],
  exports: [ToastMessageListComponent],
})
export class SharedModule {}
