import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // Usuario autenticado
  cognitoUser$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() {}

  /**
   * Método para iniciar sesión
   */
  public signIn(user: any): Promise<any> {
    return Auth.signIn(user.email, user.password);
  }

  /**
   * Método para cerrar sesión
   */
  public signOut(): Promise<any> {
    return Auth.signOut().then(() => {
      this.cognitoUser$.next(null);
    });
  }

  /**
   * Método para validar si el usuario esta autenticado
   */
  public isAuthenticated(): Promise<any> {
    return Auth.currentAuthenticatedUser();
  }

  /**
   * Método para obtener información del usuario
   */
  public getUser(): Promise<any> {
    return Auth.currentUserInfo();
  }

  /**
   * Método para actualizar la información del usuario autenticado
   * @param {any} user - usuario con los atributos de un usuario cognito
   * @param {any} attributes - atributos a actualizar
   */
  public updateUser(user: any, attributes: any): Promise<any> {
    return Auth.updateUserAttributes(user, attributes);
  }

  /**
   * Método para cambiar la contraseña cuando es usuario por primera vez
   * @param {any} user - usuario con los atributos de un usuario cognito
   * @param {string} password - contraseña
   */
  public completeNewPassword(user: any, password: string): Promise<any> {
    return Auth.completeNewPassword(user, password);
  }

  /**
   * Método para recuperar contraseña - envía correo con código de validaciòn
   * @param {string} username - correo del usuario
   */
  public forgotPassword(username: string): Promise<any> {
    return Auth.forgotPassword(username);
  }

  /**
   * Método para cambiar contraseña con código de verificación desde olvidó contraseña
   * @param {string} username - correo del usuario
   * @param {string} code - correo del usuario
   * @param {string} password - correo del usuario
   */
  public forgotPasswordSubmit(username: string, code: string, password: string): Promise<any> {
    return Auth.forgotPasswordSubmit(username, code, password);
  }

}
