import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class PasswordMatchValidator {
  /**
   * Valida que las contraseñas coincidan
   * @param {AbstractControl} controls - AbstractControl a validar
   * @return {ValidationErrors | null} - asigna el nombre del error al AbstractControl
   */
  static mustMatch: ValidatorFn = (
    controls: AbstractControl
  ): ValidationErrors | null => {
    const control = controls.get('password');
    const checkControl = controls.get('confirmPassword');

    if (checkControl?.errors && !checkControl.errors['mustMatch']) {
      return null;
    }

    if (control?.value !== checkControl?.value) {
      checkControl?.setErrors({ mustMatch: true });
      return { mustMatch: true };
    } else {
      return null;
    }
  };
}

