import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

export class ToastMessage {
  content: string;
  type: string;
  style: string;
  dismissed = false;
  id: string = uuidv4();

  constructor(content: string, type: string, style?: string) {
    this.content = content;
    this.type = type;
    this.style = style || 'info';
  }
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private messageDataSource$ = new BehaviorSubject<ToastMessage[]>([]);

  constructor() { }

  /**
   * Obtiene los mensaje de notificaciones
   * @return {Observable<ToastMessage[]>} arreglo de notificaciones
   */
  getMessages(): Observable<ToastMessage[]> {
    return this.messageDataSource$.asObservable();
  }

  /**
   * Envía los mensajes al arreglo de mensajes
   * @param {string} content - contenido del mensaje
   * @param {string} type - tipo de alerta
   * @param {string} style - estilo a aplicar
   */
  sendMessage(content: string, type: string, style: string): void {
    const message = new ToastMessage(content, type, style);
    const currMessage = this.messageDataSource$.value;
    const updMessage = [message, ...currMessage];

    this.messageDataSource$.next(updMessage);
  }

  /**
   * Remueve el mensaje del arreglo de notificaciones
   * @param {string} messageKey - identificador del mensaje
   */
  dismissMessage(messageKey: string): void {
    const currMessage = this.messageDataSource$.value;
    const dissmised = currMessage.map((message) => {
      if (message.id === messageKey) {
        message.dismissed = true;
      }
      return message;
    });
    const updMessage = dissmised.filter((message) => !message.dismissed);
    this.messageDataSource$.next(updMessage);
  }

  /**
   * Notificacion exitosa
   * @param {string} content - Contenido del mensaje
   */
  success(content: string): void {
    this.sendMessage(content, 'Success!', 'toast-success');
  }

  /**
   * Notificacion erronea
   * @param {string} content - Contenido del mensaje
   */
  error(content: string): void {
    this.sendMessage(content, 'Error!', 'toast-error');
  }

  /**
   * Notificacion informativa
   * @param {string} content - Contenido del mensaje
   */
  info(content: string): void {
    this.sendMessage(content, 'Info!', 'toast-info');
  }

  /**
   * Notificacion advertencia
   * @param {string} content - Contenido del mensaje
   */
  warning(content: string): void {
    this.sendMessage(content, 'Warning!', 'toast-warning');
  }
}
