import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify } from 'aws-amplify';
import { environment } from 'src/environments/environment';
import { AppModule } from './app/app.module';

/**
 * Configuracion de Amplify
 */
Amplify.configure({
  Auth: environment.cognito,
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
