import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToastMessage, ToastService } from 'src/app/shared/services/toast/toast.service';


@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
})
export class ToastMessageComponent implements OnInit, OnDestroy {
  private timeOut: any;

  @Input() message!: ToastMessage

  constructor(private toast: ToastService) {}

  ngOnInit() {
    this.timeOut = setTimeout(() => {
      this.dismiss(this.message.id);
    }, 7000);
  }

  /**
   * Borra el mensaje de notificacion
   */
  dismiss(itemKey: string): void {
    this.toast.dismissMessage(itemKey);
  }

  ngOnDestroy() {
    clearTimeout(this.timeOut);
  }
}
