export const environment = {
  production: true,
  cognito: {
    userPoolId: 'us-west-2_2iTNAxpu7',
    userPoolWebClientId: '1tsqjit42e7iu44lhu8abdfrpv',
  },
  keepCashApi: {
    url: 'https://dev-services.2keepcash.com',
    auth_basic: {
      user: "core-service",
      pass: "K33pC4sH@S3cuR3!"
    }
  }
};
