import { Component } from '@angular/core';
import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Observable } from 'rxjs';
import { ToastMessage, ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'app-toast-message-list',
  templateUrl: './toast-message-list.component.html',
  styleUrls: ['./toast-message-list.component.scss'],
  animations: [
    trigger('toastInOut', [
      transition(':enter', [
        animate(
          300,
          keyframes([
            style({ opacity: 0, transform: 'translateX(100%)', offset: 0 }),
            style({ opacity: 1, transform: 'translateX(0)', offset: 1.0 }),
          ])
        ),
      ]),
      transition(':leave', [
        animate(
          300,
          keyframes([
            style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
            style({ opacity: 1, transform: 'translateX(-15px)', offset: 0.7 }),
            style({ opacity: 0, transform: 'translateX(100%)', offset: 1.0 }),
          ])
        ),
      ]),
    ]),
  ],
})
export class ToastMessageListComponent {
  messages: Observable<ToastMessage[]>;

  constructor(private toast: ToastService) {
    this.messages = this.toast.getMessages();
  }
}
